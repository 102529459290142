var idioma = $('html').attr('lang');
$('.houdiniForm').each(function() {//per cada formulari
  'use strict';
  //validacio formulari
  var form = $(this);

  form.find('.protecciodades a').attr('target', '_blank');

  form.validate({
    errorElement: 'span', //default input error message container
    errorClass: 'help-block help-block-error', // default input error message class
//        focusInvalid: false, // do not focus the last invalid input
    ignore: "", // validate all fields including form hidden input
    errorPlacement: function(error, element) {
      if (element.is(':checkbox')) {
        error.insertAfter(element.closest(".md-checkbox-list, .md-checkbox-inline, .checkbox-list, .checkbox-inline"));
      } else if (element.is(':radio')) {
        error.insertAfter(element.closest(".md-radio-list, .md-radio-inline, .radio-list,.radio-inline"));
      } else {
        error.insertAfter(element); // for other inputs, just perform default behavior
      }
    },

    highlight: function(element) { // hightlight error inputs
      $(element)
        .closest('.form-group').addClass('has-error'); // set error class to the control group
    },

    unhighlight: function(element) { // revert the change done by hightlight
      $(element)
        .closest('.form-group').removeClass('has-error'); // set error class to the control group
    },

    success: function(label) {
      label
        .closest('.form-group').removeClass('has-error'); // set success class to the control group
    },
  });

  if ($(this).find('.form-steps').length > 0) {
    $(this).find('.form-steps').steps({
      headerTag: "h3",
      bodyTag: "section",
      transitionEffect: "fade",
      transitionEffectSpeed: 200,
      cssClass: "wizard",
      autoFocus: true,
      enableAllSteps: false,
      enablePagination: true,
      enableFinishButton: false,
      onStepChanging: function (event, currentIndex, newIndex)
      {
          if (newIndex < currentIndex) {
              $(this).find('div.actions').show();
              return true;
          }
          form.validate().settings.ignore = ":disabled,:hidden";
          var formValid = form.valid();
          if (!formValid) {
              return false;
          }
          if (newIndex + 1 == $(this).find('section').length) {
              $(this).find('div.actions').hide();
          } else {
              $(this).find('div.actions').show();
          }
          return formValid;
      },
      onFinishing: function (event, currentIndex)
      {
          form.validate().settings.ignore = ":disabled";
          return form.valid();
      },
      labels: {
          cancel: $.houdiniforms.messages.steps_cancel,
          current: $.houdiniforms.messages.steps_current,
          pagination: $.houdiniforms.messages.steps_pagination,
          finish: $.houdiniforms.messages.steps_finish,
          next: $.houdiniforms.messages.steps_next,
          previous: $.houdiniforms.messages.steps_previous,
          loading: $.houdiniforms.messages.steps_loading,
      }
    });
  }

  var others = $(this).find('option[value="others_field"]');
  others.each(function(){
    $(this).closest('div').find('input[type="text"]').hide();
    $(this).closest('div').find('select').on('change', function(){
      if ($(this).closest('div').find('select').val() == 'others_field') {
        $(this).closest('div').find('input[type="text"]').show();
      } else {
        $(this).closest('div').find('input[type="text"]').hide().val('');
      }
    });
  });
});


//chosen
$('.houdiniForm .chzn-select').select2();

$.fn.raty.defaults.path = '/media/img';
$.fn.raty.defaults.cancel = false;

//valoracio
$('.houdiniForm .scoreField').each(function() {
  'use strict';
  var field = $(this);
  var id = '#' + field.attr('id');
  var score = $('<div></div>');
  score.raty({
    half: true,
    hints: ['', '', '', '', ''],
    score: function() {
      return field.val();
    },
    targetScore: id
  });
  field.hide();
  field.after(score);
});

//select de dnis
$('.houdiniForm .dninifnie').each(function() {
  'use strict';
  $(this).find('select').on('change', function() {
    var value = $(this).val();
    var input = $(this).closest('div.row').find('input');

    if (value === 'DNI') {
      input.rules('add', {
        dni: true,
        cifES: false,
        nieES: false
      });
    } else if (value === 'NIF') {
      input.rules('add', {
        dni: false,
        cifES: true,
        nieES: false
      });
    } else if (value === 'NIE') {
      input.rules('add', {
        dni: false,
        cifES: false,
        nieES: true
      });
    }
  });
});

$('.dependencia').hide();
$('.dependencia input, .dependencia select').prop( "disabled", true );
$(document).on('change', '.addicional input', function(){
  var groupClass = $(this).parents('.addicional').data('code');
  if(this.checked) {
    $('.' + groupClass).show();
    $('.' + groupClass + ' input').prop( "disabled", false );
    $('.' + groupClass + ' select').prop( "disabled", false );
  } else {
    $('.' + groupClass).hide();
    $('.' + groupClass + ' input').prop( "disabled", true );
    $('.' + groupClass + ' select').prop( "disabled", true );
  }
});
$(document).on('change', '.sel_group select', function(){
  $(this).find('option').each(function(){
    var subvalor = $(this).attr('value');
    if(subvalor != ''){
      $('.' + subvalor).hide();
      $('.' + subvalor).find('input, select').prop( "disabled", true );
    }
  });

  var valor = $(this).find('option:selected').val();
  console.log(valor);
  $('.' + valor).show();
  $('.' + valor).find('input, select').prop( "disabled", false );
});
$(document).on('change', '.sel_group input', function(){
  $(this).parents('.sel_group').find('input').each(function(){
    var subvalor = $(this).val();
    $('.' + subvalor).hide();
    $('.' + subvalor).find('input, select').prop( "disabled", true );
  });
  var valor = $(this).val();
  if(this.checked) {
    $('.' + valor).show();
    $('.' + valor).find('input, select').prop( "disabled", false );
  }
});


$("div[data-autocomplete], input[data-autocomplete]").each(function(){
  var data = $(this).data('autocomplete');
  var elem = $(this);
  console.log(data);
  $.ajax({
    method: "GET",
    url: '/media/php/getUserInfo.php',
    data: data
  }).done(function( userInfo ) {
    if(userInfo != ''){
      if(elem.is('input')){
        $(elem).val(userInfo);
        $(elem).attr('readOnly', true);
      } else {
        $(elem).find('input').val(userInfo);
        $(elem).find('input').attr('readOnly', true);
      }
    }
  });
});
