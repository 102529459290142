if (jQuery.validator) {

  jQuery.validator.addClassRules({
    password: {
      required: true,
      minlength: 8
    },
    password_again: {
      equalTo: '#passw'
    }
  });

}
